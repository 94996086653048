import React from "react";
import PropTypes from "prop-types";

import BasicBtn from "../../Components/shared/BasicBtn";
import CustomText from "../../Components/shared/CustomText";
import styles from "./GDPRManager.module.scss";

import fr from "./lang/fr.json";
import en from "./lang/en.json";

const GDPRMain = ({ setEditGDPR, skipGDPR, handleValidation }) => {
  const selectedLangage = localStorage.getItem("selectedLangage")
    ? localStorage.getItem("selectedLangage") === "en"
      ? fr
      : en
    : null;
  const currentLang =
    selectedLangage || (navigator.language.includes("fr") ? fr : en);

  return (
    <div className={styles.GDPRMain}>
      <div className={styles.GDPRSkip}>
        <button
          className={styles.GDPRSkipCTA}
          type="button"
          onClick={() => skipGDPR()}
        >
          <CustomText type="span">{currentLang.GDPRMain.continue}</CustomText>
        </button>
      </div>
      <div className={styles.GDPRLeft}>
        <CustomText type="p" styleSheet="h3">
          {currentLang.GDPRMain.title}
        </CustomText>
        <CustomText type="p">{currentLang.GDPRMain.body}</CustomText>
      </div>
      <div className={styles.GDPRRight}>
        <div className={styles.GDPRButtons}>
          <BasicBtn onClick={() => setEditGDPR(true)} showIcon={false}>
            {currentLang.GDPRMain.customize}
          </BasicBtn>
          <BasicBtn
            onClick={() =>
              handleValidation({
                confirmed: true,
                gtag: true,
                facebookpixel: true,
              })
            }
            showIcon={false}
          >
            {currentLang.GDPRMain.confirm}
          </BasicBtn>
        </div>
      </div>
    </div>
  );
};

GDPRMain.propTypes = {
  setEditGDPR: PropTypes.any,
  handleValidation: PropTypes.func,
  skipGDPR: PropTypes.func,
};

export default GDPRMain;
